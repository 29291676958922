var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container md:p-30px py-20px px-15px border-grey02" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex sm:justify-between sm:items-center sm:flex-row flex-col mb-20px"
        },
        [
          _c("div", { staticClass: "w-fit" }, [
            _c(
              "h1",
              {
                staticClass:
                  "sm:text-lg text-sm text-black01 font-medium sm:mb-0 mb-15px"
              },
              [_vm._v("\n        " + _vm._s("案件info") + "\n      ")]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "project-container flex items-center justify-end" },
            [
              _c("v-select", {
                staticClass: "text-xs mr-10px md:w-130px sm:w-70px w-50px",
                attrs: {
                  options: _vm.customer_filters,
                  reduce: function(x) {
                    return x.value
                  }
                },
                model: {
                  value: _vm.customer,
                  callback: function($$v) {
                    _vm.customer = $$v
                  },
                  expression: "customer"
                }
              }),
              _vm._v(" "),
              _c("v-select", {
                staticClass: "text-xs mr-10px md:w-130px sm:w-70px w-50px",
                attrs: {
                  options: _vm.worker_filters,
                  reduce: function(x) {
                    return x.value
                  }
                },
                model: {
                  value: _vm.worker,
                  callback: function($$v) {
                    _vm.worker = $$v
                  },
                  expression: "worker"
                }
              }),
              _vm._v(" "),
              _c("v-select", {
                staticClass: "text-xs mr-10px md:w-75px sm:w-70px w-50px",
                attrs: {
                  options: _vm.manager_filters,
                  reduce: function(x) {
                    return x.value
                  }
                },
                model: {
                  value: _vm.manager,
                  callback: function($$v) {
                    _vm.manager = $$v
                  },
                  expression: "manager"
                }
              }),
              _vm._v(" "),
              _c("v-select", {
                staticClass: "text-xs mr-10px md:w-130px sm:w-70px w-50px",
                attrs: {
                  options: _vm.manager_status_filters,
                  reduce: function(x) {
                    return x.value
                  }
                },
                model: {
                  value: _vm.manager_status,
                  callback: function($$v) {
                    _vm.manager_status = $$v
                  },
                  expression: "manager_status"
                }
              }),
              _vm._v(" "),
              _c("v-select", {
                staticClass: "text-xs mr-10px md:w-90px sm:w-70px w-50px",
                attrs: {
                  options: _vm.sort_types,
                  reduce: function(x) {
                    return x.value
                  }
                },
                model: {
                  value: _vm.sort,
                  callback: function($$v) {
                    _vm.sort = $$v
                  },
                  expression: "sort"
                }
              }),
              _vm._v(" "),
              _c("v-select", {
                staticClass: "text-xs mr-10px md:w-75px sm:w-70px w-50px",
                attrs: {
                  options: _vm.search_directions,
                  reduce: function(x) {
                    return x.value
                  }
                },
                model: {
                  value: _vm.direction,
                  callback: function($$v) {
                    _vm.direction = $$v
                  },
                  expression: "direction"
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "text-white00 text-xs md:px-20px px-10px leading-loose bg-grey04 rounded-20px m-0 flex-shrink-0",
                  attrs: { type: "button" },
                  on: { click: _vm.search }
                },
                [_vm._v("\n        " + _vm._s("抽出") + "\n      ")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "container__content" }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap -mx-10px" },
          _vm._l(_vm.projects, function(project) {
            return _c("projects-overview-item", {
              key: project.id,
              staticClass: "md:px-10px px-7.5px",
              attrs: { project: project },
              on: {
                "open-download": _vm.openModalOverviewDownload,
                "open-complete": _vm.openModalOverviewComplete
              }
            })
          }),
          1
        )
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("projects-modal-overview-download", {
        class: { hidden: _vm.overview_download.hidden },
        attrs: {
          url: _vm.overview_download.url,
          name: _vm.overview_download.name
        },
        on: {
          close: _vm.closeModalOverviewDownload,
          submit: _vm.closeModalOverviewDownload
        }
      }),
      _vm._v(" "),
      _c("projects-modal-overview-complete", {
        class: { hidden: _vm.overview_complete.hidden },
        attrs: { url: _vm.overview_complete.url },
        on: {
          close: _vm.closeModalOverviewComplete,
          submit: _vm.closeModalOverviewComplete
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container__footer" }, [
      _c("div", {
        staticClass: "container__footer__block container__footer__block--left"
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container__footer__block" }),
      _vm._v(" "),
      _c("div", {
        staticClass: "container__footer__block container__footer__block--right"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }