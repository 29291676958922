var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container p-30px border-grey02 w-full" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex md:flex-row flex-col md:justify-between md:items-center mb-30px w-full"
        },
        [
          _c("div", { staticClass: "w-fit md:mb-0 mb-10px" }, [
            _c("div", { staticClass: "flex items-center" }, [
              _c("img", {
                staticClass: "md:h-30px h-24px mr-10px",
                attrs: { src: "/images/svg/projects/icon-list.svg" }
              }),
              _vm._v(" "),
              _c(
                "h1",
                { staticClass: "text-black01 font-medium md:text-lg text-sm" },
                [_vm._v(_vm._s(_vm.title))]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", {}),
          _vm._v(" "),
          _c("div", { staticClass: "md:self-auto self-end" }, [
            _c(
              "div",
              {},
              [
                _vm.search
                  ? [
                      _c("span", { staticClass: "search-result-count" }, [
                        _c(
                          "span",
                          { staticClass: "search-result-count-number" },
                          [_vm._v(_vm._s(_vm.projects.total))]
                        ),
                        _vm._v(
                          "\n            " +
                            _vm._s("件見つかりました") +
                            "\n          "
                        )
                      ])
                    ]
                  : [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px mr-10px",
                            attrs: { href: _vm.urls.create }
                          },
                          [_vm._v(_vm._s("新規案件作成"))]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px",
                            on: { click: _vm.openSearchDialog }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s("案件検索") +
                                "\n            "
                            )
                          ]
                        )
                      ])
                    ]
              ],
              2
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "container__content w-full overflow-x-auto" }, [
        !_vm.search
          ? _c(
              "div",
              { staticClass: "tabs-wrapper project-tabs" },
              _vm._l(_vm.statuses, function(status_item, index) {
                return _c(
                  "button",
                  {
                    key: index,
                    staticClass: "tab",
                    class: { active: status_item.value == _vm.status },
                    on: {
                      click: function($event) {
                        return _vm.selectStatus(status_item.value)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(status_item.label))
                    ])
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "table",
          {
            staticClass: "project-table md:whitespace-normal whitespace-nowrap"
          },
          [
            _c("thead", [
              _c(
                "tr",
                _vm._l(_vm.tableHeaders, function(header, index) {
                  return _c(
                    "th",
                    { key: index },
                    [
                      !header.sortable
                        ? [
                            _vm._v(
                              "\n              " +
                                _vm._s(header.name) +
                                "\n            "
                            )
                          ]
                        : [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    return _vm.changeSort(header.key)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(header.name) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "fa",
                              class: {
                                "fa-sort":
                                  _vm.sortProperty != header.key ||
                                  (_vm.sortDirection != "asc" &&
                                    _vm.sortDirection != "desc"),
                                "fa-sort-up":
                                  _vm.sortProperty == header.key &&
                                  _vm.sortDirection == "asc",
                                "fa-sort-down":
                                  _vm.sortProperty == header.key &&
                                  _vm.sortDirection == "desc"
                              }
                            })
                          ]
                    ],
                    2
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.projects.data, function(project) {
                return _c("tr", { key: project.id }, [
                  _c("td", { staticClass: "w-1/10 min-w-100px" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.formatISODate(project.created_at, "yyyy-MM-dd")
                        )
                    ),
                    _c("br"),
                    _vm._v(
                      _vm._s(
                        _vm.formatISODate(project.created_at, "HH:mm:ss")
                      ) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "w-1/10 min-w-100px" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.projectStatusToName(project.status)) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "w-1/10 min-w-100px" }, [
                    _c(
                      "a",
                      {
                        staticClass: "opacity decoration",
                        attrs: { href: _vm.buildProjectsShowUrl(project.id) }
                      },
                      [_vm._v(_vm._s(project.friendly_id))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "w-3/10 min-w-150px" }, [
                    _vm._v(
                      _vm._s(project.customer ? project.customer.name : "")
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "w-3/10 min-w-150px" }, [
                    _vm._v(_vm._s(project.name))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "w-1/10 min-w-100px" }, [
                    _vm._v(_vm._s(project.manager ? project.manager.name : ""))
                  ])
                ])
              }),
              0
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container__footer" }, [
        _c("div", { staticClass: "container__footer__block" }, [
          _c("div", [
            _c(
              "div",
              [
                _c("pagination", {
                  attrs: {
                    links: _vm.projects.links,
                    totalPages: _vm.projects.last_page
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.search
              ? _c("div", { staticClass: "footer-buttons" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px lg:mr-10px lg:mb-0 mb-10px",
                      attrs: { href: _vm.urls.projects.list }
                    },
                    [_vm._v(_vm._s("一覧へ戻る"))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px",
                      on: { click: _vm.openSearchDialog }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s("検索条件変更") +
                          "\n          "
                      )
                    ]
                  )
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("search-dialog", {
        attrs: { "dialog-data": _vm.searchDialog },
        on: {
          "close-dialog": _vm.closeSearchDialog,
          "submit-search": _vm.submitSearch
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }