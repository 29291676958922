var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", { attrs: { viewBox: "0 0 10 10" } }, [
    _c("path", {
      staticClass: "fg",
      staticStyle: { "stroke-width": "1" },
      attrs: {
        d:
          "M 4.9994943,5.908769 0.91028623,9.998989 0,9.088702 4.0902195,4.999494 0,0.910286 0.91028623,0 4.9994943,4.090219 9.0897138,0 10,0.910286 5.9087691,4.999494 9.9989886,9.089714 9.0887023,10 Z"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }