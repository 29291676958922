import { render, staticRenderFns } from "./close.vue?vue&type=template&id=9b323770&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/shino/est/estimate-system/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9b323770')) {
      api.createRecord('9b323770', component.options)
    } else {
      api.reload('9b323770', component.options)
    }
    module.hot.accept("./close.vue?vue&type=template&id=9b323770&", function () {
      api.rerender('9b323770', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/icons/close.vue"
export default component.exports