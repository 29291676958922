$(function(){
    // sidenav
    $('.js-sidenav-toggle').on('click', function(){
        $(this).toggleClass('rotate-180');
        $('.js-nav-title').toggleClass('hidden');
        $('.nav__inner').toggleClass('ml-20px ml-10px');
        $('.nav').toggleClass('w-220px w-55px');
        $('#main__content').toggleClass('lg:pl-220px lg:pl-55px');
        $('.js-pl').toggleClass('pl-10px pl-20px')

        $('#js-nav01').slideUp();
        $('#js-nav-toggle01').removeClass('rotate-90');
        $('#js-nav02').slideUp();
        $('#js-nav-toggle02-arrow').removeClass('rotate-90');
    }),
    // nav child menu
    $('#js-nav-toggle01').on('click',function(){
        $(this).toggleClass('rotate-90')
        $('#js-nav01').slideToggle();
    }),
    $('#js-nav-toggle02').on('click',function(){
        $('#js-nav-toggle02-arrow').toggleClass('rotate-90')
        $('#js-nav02').slideToggle();
    })
})
