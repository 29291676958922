<template>
    <div class="w-full h-full absolute top-0 left-0 z-20" 
        style="background-color: rgba(0, 0, 0, 0.3);">
        <div class="w-full h-full relative">
            <div class="w-300px absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                <div class="w-full bg-grey04 text-center text-xs text-white00 font-bold leading-25px rounded-t-10px relative">
                    指示書PDFダウンロード
                    <div class="w-14px h-14px bg-white00 rounded-full absolute top-1/2 right-10px transform -translate-y-1/2 cursor-pointer">
                        <div class="w-full h-full relative">
                            <span class="h-px w-10px bg-grey04 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 rotate-45"></span>
                            <span class="h-px w-10px bg-grey04 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 -rotate-45"></span>
                        </div>
                    </div>
                </div>
                <div class="w-full bg-white00 py-30px rounded-b-10px">
                    <p class="text-center text-xs font-bold text-black01 mb-30px">指示書PDFをダウンロードしますか？</p>
                    <div class="flex justify-center items-center">
                        <a href="" class="block px-15px leading-loose text-white00 text-xs bg-grey04 rounded-20px mr-20px">ダウンロード</a>
                        <a href="" class="block px-20px leading-loose text-white00 text-xs bg-grey04 rounded-20px">やめる</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default ({
    data() {
        return {
            titles:[
                {
                    key: "download",
                    name: "指示書PDFダウンロード",
                },
                {
                    key: "complate",
                    name: "作業完了",
                },
            ],
            comments: [
                {
                    key: "download",
                    name: "指示書PDFをダウンロードしますか？",
                },
                {
                    key: "complate",
                    name: "作業を完了しますか？",
                },
            ]
        };
    },
})
</script>
