<template>
  <div
    class="
      md:w-1/3
      xs:w-1/2
      w-full
      xs:min-w-160px
      md:min-w-220px
      lg:max-w-220px
      md:px-10px
      px-7.5px
      md:pb-20px
      pb-15px
    "
  >
    <div class="w-full relative overflow-hidden shadow-lg">
      <transition name="fade">
        <div
          v-show="show"
          class="
            border-4
            rounded-10px
            w-full
            h-full
            absolute
            top-1/2
            left-1/2
            transform
            -translate-y-1/2 -translate-x-1/2
            z-10
          "
          style="background-color: rgba(50, 50, 50, 0.9)"
        >
          <div class="w-full h-full relative">
            <div
              class="
                absolute
                top-1/2
                left-1/2
                transform
                -translate-y-1/2 -translate-x-1/2
              "
            >
              <div
                @click="openModalOverviewDownload"
                id="projects-download"
                class="
                  js-toggle-download-modal
                  block
                  text-base text-white00
                  font-light
                  cursor-pointer
                  mb-20px
                "
              >
                ダウンロード
              </div>
              <div
                @click="openModalOverviewComplete"
                id="projects-complate"
                class="block text-base text-white00 font-light cursor-pointer"
              >
                作業完了
              </div>
            </div>
            <div
              @click="show = !show"
              class="w-20px h-20px absolute top-10px right-10px"
            >
              <div class="relative w-full h-full cursor-pointer">
                <div
                  class="
                    bg-white00
                    h-2px
                    w-full
                    absolute
                    top-1/2
                    left-1/2
                    transform
                    -translate-y-1/2 -translate-x-1/2
                    rotate-45
                  "
                ></div>
                <div
                  class="
                    bg-white00
                    h-2px
                    w-full
                    absolute
                    top-1/2
                    left-1/2
                    transform
                    -translate-y-1/2 -translate-x-1/2
                    -rotate-45
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div
        v-bind:class="[`status-border-${project.progression}`]"
        class="absolute top-0 left-0 w-full h-full border-4 rounded-10px p-10px"
      >
        <div class="flex justify-between sm:mb-20px mb-10px">
          <div class="flex items-start">
            <div
              v-bind:class="{
                'manager-disabled': project.processing_enabled == false,
                'manager-downloaded':
                  project.processing_status == 1 ||
                  project.processing_status == 2,
              }"
              class="
                processing-manager
                sm:text-xs
                text-3xs
                sm:leading-loose
                leading-normal
                sm:w-2em
                w-1.5em
                mr-5px
              "
            >
              {{ "加" }}
            </div>
            <div
              v-bind:class="{
                'manager-disabled': project.output_enabled == false,
                'manager-downloaded':
                  project.output_status == 1 || project.output_status == 2,
              }"
              class="
                output-manager
                sm:text-xs
                text-3xs
                sm:leading-loose
                leading-normal
                sm:w-2em
                w-1.5em
                mr-5px
              "
            >
              {{ "出" }}
            </div>
            <div
              v-bind:class="{
                'manager-disabled': project.technical_enabled == false,
                'manager-downloaded':
                  project.technical_status == 1 ||
                  project.technical_status == 2,
              }"
              class="
                technical-manager
                sm:text-xs
                text-3xs
                sm:leading-loose
                leading-normal
                sm:w-2em
                w-1.5em
                mr-5px
              "
            >
              {{ "技" }}
            </div>
            <div
              v-if="project.affiliate_authored"
              class="
                affiliate-authored
                sm:text-xs
                text-3xs
                sm:leading-loose
                leading-normal
                sm:w-2em
                w-1.5em
              "
            >
              {{ "外" }}
            </div>
          </div>
          <div
            @click="show = !show"
            class="
              block
              sm:w-20px
              w-16px
              sm:h-16px
              h-14px
              relative
              cursor-pointer
            "
          >
            <span
              class="
                h-2px
                w-full
                bg-black01
                absolute
                top-0
                left-1/2
                transform
                -translate-x-1/2
              "
            ></span>
            <span
              class="
                h-2px
                w-full
                bg-black01
                absolute
                top-1/2
                left-1/2
                transform
                -translate-y-1/2 -translate-x-1/2
              "
            ></span>
            <span
              class="
                h-2px
                w-full
                bg-black01
                absolute
                bottom-0
                left-1/2
                transform
                -translate-x-1/2
              "
            ></span>
          </div>
        </div>
        <a v-bind:href="`/projects/${project.id}/show`">
          <div class="w-full relative">
            <div
              class="
                absolute
                top-0
                left-0
                sm:text-base
                text-xs text-black01
                font-bold
                sm:h-45px
                h-35px
                mb-20px
                overflow-ellipsis overflow-hidden
              "
            >
              {{ project.name }}
            </div>
            <div class="pt-2/5"></div>
          </div>
          <hr class="border-t-2 border-dashed border-grey03" />
          <div class="pt-5px">
            <div class="flex items-center justify-between">
              <div>
                <span
                  class="sm:text-base text-xs text-black01 font-bold relative"
                >
                  <span
                    v-bind:class="[amBackgroundColor]"
                    class="
                      w-20px
                      h-20px
                      rounded-20px
                      absolute
                      top-1/2
                      left-1/2
                      transform
                      -translate-y-1/2 -translate-x-1/2
                    "
                  ></span>
                  AM
                </span>
                ·
                <span
                  class="sm:text-base text-xs text-black01 font-bold relative"
                >
                  <span
                    v-bind:class="[pmBackgroundColor]"
                    class="
                      w-20px
                      h-20px
                      rounded-20px
                      absolute
                      top-1/2
                      left-1/2
                      transform
                      -translate-y-1/2 -translate-x-1/2
                    "
                  ></span>
                  PM
                </span>
              </div>
              <div
                v-bind:class="[`type-bg-${project.type}`]"
                class="
                  sm:text-base
                  text-xs text-white00
                  font-bold
                  px-10px
                  py-5px
                  rounded-20px
                "
              >
                {{ project.type_string }}
              </div>
            </div>
            <div class="sm:text-base text-xs text-black01 font-bold">
              {{ formatISODate(project.due_date, "MMMdo EEEEE") }}
              {{
                project.type == 0
                  ? formatISODate(
                      this.parseToISOString(
                        this.project.due_time,
                        "HH:mm",
                        new Date()
                      ),
                      "a HH:mm",
                      'en'
                    )
                  : project.due_time_period == 0
                  ? "AM"
                  : "PM"
              }}
            </div>
          </div>
        </a>
      </div>
      <div class="pt-full"></div>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<script>
export default {
  props: {
    project: Object,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    amBackgroundColor() {
      if (this.project.type == 0) {
        if (
          this.formatISODate(
            this.parseToISOString(this.project.due_time, "HH:mm", new Date()),
            "aaaaa"
          ) == "a"
        ) {
          return `ampm-border-${this.project.type} border-2`;
        }
      } else if (this.project.due_time_period == 0) {
        return `ampm-border-${this.project.type} border-2`;
      }
      return "";
    },
    pmBackgroundColor() {
      if (this.project.type == 0) {
        if (
          this.formatISODate(
            this.parseToISOString(this.project.due_time, "HH:mm", new Date()),
            "aaaaa"
          ) == "p"
        ) {
          return `ampm-border-${this.project.type} border-2`;
        }
      } else if (this.project.due_time_period == 1) {
        return `ampm-border-${this.project.type} border-2`;
      }
      return "";
    },
  },
  methods: {
    openModalOverviewDownload() {
      this.$emit("open-download", { id: this.project.id, name: `${this.formatISODate(this.project.due_date, 'MMdd')}_${this.project.name}.pdf` });
    },
    openModalOverviewComplete() {
      this.$emit("open-complete", this.project.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.affiliate-authored,
.processing-manager,
.output-manager,
.technical-manager,
.manager-downloaded,
.manager-disabled {
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
}
.affiliate-authored {
  color: #909090;
  border-color: #909090;
  border-width: 1px;
}

.processing-manager {
  background-color: #bb1e1e !important;
}
.output-manager {
  background-color: #f39e2e !important;
}
.technical-manager {
  background-color: #01b58b !important;
}
.manager-downloaded {
  background-color: #808080 !important;
}
.manager-disabled {
  background-color: #000000 !important;
}

.status-border-0 {
  border-color: #ff00a2 !important;
}
.status-border-1 {
  border-color: #f39e2e !important;
}
.status-border-2 {
  border-color: gray !important;
}
.status-border-3 {
  border-color: #3e91ff !important;
}
.type-bg-0 {
  background-color: #3e91ff !important;
}
.type-bg-1 {
  background-color: #ff3e3e !important;
}
.type-bg-2 {
  background-color: #1ac300 !important;
}
.type-bg-3 {
  background-color: #1ac300 !important;
}
.ampm-border-0 {
  border-color: #3e91ff !important;
}
.ampm-border-1 {
  border-color: #ff3e3e !important;
}
.ampm-border-2 {
  border-color: #1ac300 !important;
}
.ampm-border-3 {
  border-color: #1ac300 !important;
}
</style>
