<template>
  <div class="container md:p-30px py-20px px-15px border-grey02">
    <div
      class="flex sm:justify-between sm:items-center sm:flex-row flex-col mb-20px"
    >
      <div class="w-fit">
        <h1 class="sm:text-lg text-sm text-black01 font-medium sm:mb-0 mb-15px">
          {{ "案件info" }}
        </h1>
      </div>
      <div class="project-container flex items-center justify-end">
        <v-select
          v-bind:options="customer_filters"
          v-bind:reduce="(x) => x.value"
          v-model="customer"
          class="text-xs mr-10px md:w-130px sm:w-70px w-50px"
        ></v-select>
        <v-select
          v-bind:options="worker_filters"
          v-bind:reduce="(x) => x.value"
          v-model="worker"
          class="text-xs mr-10px md:w-130px sm:w-70px w-50px"
        ></v-select>

        <v-select
          v-bind:options="manager_filters"
          v-bind:reduce="(x) => x.value"
          v-model="manager"
          class="text-xs mr-10px md:w-75px sm:w-70px w-50px"
        ></v-select>
        <v-select
          v-bind:options="manager_status_filters"
          v-bind:reduce="(x) => x.value"
          v-model="manager_status"
          class="text-xs mr-10px md:w-130px sm:w-70px w-50px"
        ></v-select>
        <v-select
          v-bind:options="sort_types"
          v-bind:reduce="(x) => x.value"
          v-model="sort"
          class="text-xs mr-10px md:w-90px sm:w-70px w-50px"
        ></v-select>
        <v-select
          v-bind:options="search_directions"
          v-bind:reduce="(x) => x.value"
          v-model="direction"
          class="text-xs mr-10px md:w-75px sm:w-70px w-50px"
        ></v-select>
        <button
          class="text-white00 text-xs md:px-20px px-10px leading-loose bg-grey04 rounded-20px m-0 flex-shrink-0"
          type="button"
          v-on:click="search"
        >
          {{ "抽出" }}
        </button>
      </div>
    </div>
    <div class="container__content">
      <div class="flex flex-wrap -mx-10px">
        <projects-overview-item
          v-for="project in projects"
          v-bind:key="project.id"
          v-bind:project="project"
          v-on:open-download="openModalOverviewDownload"
          v-on:open-complete="openModalOverviewComplete"
          class="md:px-10px px-7.5px"
        ></projects-overview-item>
      </div>
    </div>
    <div class="container__footer">
      <div
        class="container__footer__block container__footer__block--left"
      ></div>
      <div class="container__footer__block"></div>
      <div
        class="container__footer__block container__footer__block--right"
      ></div>
    </div>

    <projects-modal-overview-download
      :url="overview_download.url"
      :name="overview_download.name"
      :class="{ hidden: overview_download.hidden }"
      v-on:close="closeModalOverviewDownload"
      v-on:submit="closeModalOverviewDownload"
    ></projects-modal-overview-download>
    <projects-modal-overview-complete
      :url="overview_complete.url"
      :class="{ hidden: overview_complete.hidden }"
      v-on:close="closeModalOverviewComplete"
      v-on:submit="closeModalOverviewComplete"
    ></projects-modal-overview-complete>
  </div>
</template>

<style scoped>
.button {
  padding: 0 15px;
}
</style>

<script>
export default {
  props: {
    customer_filters: Array,
    worker_filters: Array,
    manager_filters: Array,
    sort_types: Array,
    search_directions: Array,
    urls: Object,
    projects: Array,
    user: Object,
  },
  data() {
    return {
      manager_status_filters: [],
      customer: null,
      worker: null,
      manager: -1,
      manager_status: -1,
      sort: "delivery",
      direction: "asc",
      overview_download: {
        url: null,
        name: null,
        project_id: null,
        hidden: true,
      },
      overview_complete: {
        url: null,
        hidden: true,
      },
    };
  },
  watch: {
    manager: function (newVal, oldVal) {
      this.updateDeparmentSelect(newVal);
    },
  },
  methods: {
    updateDeparmentSelect(newVal) {
      var option = this.getSelectedManagerOption(newVal);
      this.manager_status_filters = option.statuses;
      if (!option.statuses.some((x) => x.value == this.manager_status)) {
        this.manager_status = option.statuses[0].value;
      } else {
        this.manager_status = this.manager_status;
      }
    },
    search() {
      var params = new URLSearchParams(window.location.search);

      if (this.customer != null && !Number.isNaN(this.customer) && this.customer != "") {
        params.set("customer", this.customer);
      } else {
        params.delete("customer");
      }
      if (this.worker != null && !Number.isNaN(this.worker) && this.worker != "") {
        params.set("worker", this.worker);
      } else {
        params.delete("worker");
      }
      params.set("manager", this.manager);
      params.set("manager_status", this.manager_status);
      params.set("sort", this.sort);
      params.set("direction", this.direction);
      window.location.search = params.toString();
    },
    getSelectedManagerOption(selected) {
      var option = this.manager_filters.find((x) => x.value == selected);
      return option;
    },
    openModalOverviewDownload({ id, name }) {
      this.overview_download.project_id = id;
      this.overview_download.name = name;
      this.overview_download.url = this.urls.download.replace(
        /__PROJECT_ID__/,
        id
      );
      this.overview_download.url = this.overview_download.url + "?download=true";
      this.overview_download.hidden = false;
    },
    closeModalOverviewDownload(submit) {
      if (submit) {
        var project = this.projects.find(
          (x) => x.id == this.overview_download.project_id
        );
        if (project) {
          if (this.user.department == 1 && project.output_status == 0) {
            project.output_status = 1;
          }
          if (this.user.department == 2 && project.technical_status == 0) {
            project.technical_status = 1;
          }
          if (this.user.department == 3 && project.processing_status == 0) {
            project.processing_status = 1;
          }

          if (
            (!project.output_enabled || project.output_status == 2) &&
            (!project.technical_enabled || project.technical_status == 2) &&
            (!project.processing_enabled || project.processing_status == 2)
          ) {
            // Do nothing, it should be complete
          } else if (
            this.user.department != 4 &&
            project.output_enabled &&
            (project.output_status == 1 || project.output_status == 2) &&
            project.technical_enabled &&
            (project.technical_status == 1 || project.technical_status == 2) &&
            project.processing_enabled &&
            (project.processing_status == 1 || project.processing_status == 2)
          ) {
            project.progression = 3;
          }
        }
        // window.location.href = this.overview_download.url;
      }

      this.overview_download.hidden = true;
    },
    openModalOverviewComplete(id) {
      this.overview_complete.url = this.urls.complete.replace(
        /__PROJECT_ID__/,
        id
      );
      this.overview_complete.hidden = false;
    },
    closeModalOverviewComplete(submit) {
      if (submit) {
        var data = {
          _token: document.querySelector('meta[name="csrf-token"]').content,
        };
        var form = document.createElement("form");
        form.style.visibility = "hidden";
        form.method = "POST";
        form.action = this.overview_complete.url;
        for (const [key, value] of Object.entries(data)) {
          console.log(`${key}: ${value}`);
          var input = document.createElement("input");
          input.name = key;
          input.value = value;
          form.appendChild(input);
        }
        document.body.appendChild(form);
        form.submit();
      }

      this.overview_complete.hidden = true;
    },
  },
  mounted() {
    this.manager = this.manager_filters[0].value;
    this.manager_status_filters = this.manager_filters[0].statuses;
    this.manager_status = 0;

    var params = new URLSearchParams(window.location.search);
    if (params.has("sort")) {
      this.sort = params.get("sort");
    }
    if (params.has("direction")) {
      this.direction = params.get("direction");
    }
    if (params.has("customer")) {
      this.customer = parseInt(params.get("customer"));
    }
    if (params.has("worker")) {
      this.worker = parseInt(params.get("worker"));
    }
    if (params.has("manager")) {
      this.manager = parseInt(params.get("manager"));
    }
    if (params.has("manager_status")) {
      this.manager_status = parseInt(params.get("manager_status"));
    }
  },
};
</script>
