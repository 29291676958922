<template>
  <div id="messages-container" class="container container--messages p-0">
    <ul class="messages-list">
      <li
        v-for="(error, index) in errors"
        v-bind:key="index"
        class="message message--error"
      >
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
      initialErrors: {
          type: Array,
          default: [],
      }
  },
  data() {
    return {
      errors: this.initialErrors,
    };
  },
};
</script>
