/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

import Vue from "vue";
window.Vue = Vue;

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/ja';

Vue.component("date-picker", DatePicker);
import "vue2-datepicker/index.css";

import { format, parse, parseISO } from "date-fns";
import { enUS, ja } from 'date-fns/locale';

Vue.mixin({
    methods: {
        formatISODate(value, formatString, locale) {
            return format(parseISO(value), formatString, { locale: locale == 'en' ? enUS : ja });
        },
        parseToISOString(value, formatString, referenceDate) {
            return parse(value, formatString, referenceDate).toISOString();
        }
    }
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context("./", true, /\.vue$/i);
files.keys().map(key =>
    Vue.component(
        key
            .split("/")
            .pop()
            .split(".")[0],
        files(key).default
    )
);

moment.locale('ja');
