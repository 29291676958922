<template>
  <div class="container p-30px border-grey02">
    <div class="flex justify-between items-center mb-30px">
      <div class="w-fit">
        <div class="flex items-center">
          <img
            :src="'/images/svg/projects/icon-list.svg'"
            class="h-30px mr-10px"
          />
          <h1 class="text-black01 font-medium">{{ title }}</h1>
        </div>
      </div>
      <div class=""></div>
      <div class="">
        <div class="">
          <template v-if="search">
            <span class="search-result-count">
              <span class="search-result-count-number">{{
                project_archives.total
              }}</span>
              {{ "件見つかりました" }}
            </span>
          </template>
          <template v-else>
            <div class="flex">
              <button
                class="block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px"
                v-on:click="openSearchDialog"
              >
                {{ "完了検索" }}
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="container__content">
      <table class="project-table overflow-x-scroll">
        <thead>
          <tr>
            <template v-for="(header, index) in tableHeaders">
              <th
                :key="index"
                v-if="!(header.hide_for_affiliate == true && isUserAffiliate)"
              >
                <template v-if="!header.sortable">
                  {{ header.name }}
                </template>
                <template v-else>
                  <a href="#" v-on:click="changeSort(header.key)">
                    {{ header.name }}
                  </a>
                  <i
                    class="fa"
                    v-bind:class="{
                      'fa-sort':
                        sortProperty != header.key ||
                        (sortDirection != 'asc' && sortDirection != 'desc'),
                      'fa-sort-up':
                        sortProperty == header.key && sortDirection == 'asc',
                      'fa-sort-down':
                        sortProperty == header.key && sortDirection == 'desc',
                    }"
                  ></i>
                </template>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="archive in project_archives.data" v-bind:key="archive.id">
            <td class="w-3/10 min-w-100px">
              <a
                class="opacity decoration"
                :href="buildProjectArchiveShowUrl(archive.id)"
                >{{ archive.friendly_id }}</a
              >
            </td>
            <td class="w-3/10 min-w-100px">
              {{ formatISODate(archive.date, "yyyy/MM") }}
            </td>
            <td v-if="!isUserAffiliate" class="w-3/10 min-w-100px">
              {{ archive.project_count }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="container__footer">
      <div class="container__footer__block">
        <div>
          <div>
            <pagination
              :links="project_archives.links"
              :totalPages="project_archives.last_page"
            ></pagination>
          </div>
          <div v-if="search" class="footer-buttons">
            <a
              class="block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px lg:mr-10px lg:mb-0 mb-10px"
              v-bind:href="urls.projects.archive"
              >{{ "一覧へ戻る" }}</a
            >
            <button
              class="block text-white00 text-xs px-20px leading-loose bg-grey04 rounded-20px"
              v-on:click="openSearchDialog"
            >
              {{ "検索条件変更" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <search-dialog
      :dialog-data="searchDialog"
      v-on:close-dialog="closeSearchDialog"
      v-on:submit-search="submitSearch"
    ></search-dialog>
  </div>
</template>

<style scoped>
a {
  color: #323232;
}
</style>

<script>
export default {
  props: {
    urls: Object,
    project_archives: Object,
    user: Object,
  },
  data() {
    return {
      title: "完了一覧",
      tableHeaders: [
        {
          sortable: true,
          key: "friendly_id",
          name: "完了ID",
        },
        {
          sortable: true,
          key: "date",
          name: "年月",
        },
        {
          sortable: true,
          key: "project_count",
          name: "完了件数",
          hide_for_affiliate: true,
        },
      ],
      sortProperty: null,
      sortDirection: null,
      search: false,
      searchDialog: {
        shown: false,
        title: "完了検索",
        submitLabel: "検索",
        fields: [
          {
            property: "friendly_id",
            label: "ID",
            type: "text",
            value: "",
            default: "",
            class: "full-width",
          },
        ],
      },
    };
  },
  computed: {
    isUserAffiliate: function () {
      if (this.user.affiliate) {
        return true;
      }
      return false;
    },
  },
  methods: {
    buildProjectArchiveShowUrl: function (id) {
      return this.urls.projects.archiveShow.replace(
        /__PROJECT_ARCHIVE_ID__/g,
        id
      );
    },
    openSearchDialog: function () {
      this.searchDialog.shown = true;
    },
    closeSearchDialog: function () {
      this.searchDialog.shown = false;
    },
    submitSearch: function () {
      //this.closeSearchDialog();
      var params = new URLSearchParams(window.location.search);
      params.set("search", true);
      this.searchDialog.fields.forEach((field) => {
        params.delete(field.property);
        if (
          field.value != null &&
          !Number.isNaN(field.value) &&
          field.value != ""
        ) {
          params.set(field.property, field.value);
        }
      });
      window.location.search = params.toString();
    },
    changeSort: function (sort_key) {
      var sortDirection = this.sortDirection;
      if (this.sortProperty != sort_key) {
        sortDirection = "null";
      }
      this.sortProperty = sort_key;
      switch (sortDirection) {
        case "null":
          this.sortDirection = "asc";
          break;
        case "asc":
          this.sortDirection = "desc";
          break;
        case "desc":
          this.sortProperty = null;
          this.sortDirection = null;
          break;
      }

      var params = new URLSearchParams(window.location.search);
      params.delete("page");
      if (this.sortProperty !== null) {
        params.set("sort", this.sortProperty);
        params.set("direction", this.sortDirection);
      } else {
        params.delete("sort");
        params.delete("direction");
      }
      window.location.search = params.toString();
    },
  },
  mounted() {
    var url = new URL(window.location.href);
    var params = new URLSearchParams(url.search);
    if (params.has("search")) {
      this.search = parseBoolean(params.get("search"));
    }
    if (params.has("sort")) {
      this.sortProperty = params.get("sort");
    }
    if (params.has("direction")) {
      this.sortDirection = params.get("direction");
    }
    this.searchDialog.fields.forEach((field) => {
      if (field.cast == "integer") {
        field.value = parseInt(params.get(field.property));
      } else {
        field.value = params.get(field.property);
      }
    });
  },
};
</script>
