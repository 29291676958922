var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container container--messages p-0",
      attrs: { id: "messages-container" }
    },
    [
      _c(
        "ul",
        { staticClass: "messages-list" },
        _vm._l(_vm.errors, function(error, index) {
          return _c(
            "li",
            { key: index, staticClass: "message message--error" },
            [_vm._v("\n      " + _vm._s(error) + "\n    ")]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }