var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full h-full fixed top-0 left-0 z-20" }, [
    _c("div", { staticClass: "w-full h-full relative" }, [
      _c("div", {
        staticClass: "w-full h-full absolute top-0 left-0",
        staticStyle: { "background-color": "rgba(0, 0, 0, 0.3)" },
        on: { click: _vm.close }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "w-300px absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-full bg-grey04 text-center text-xs text-white00 font-bold leading-25px rounded-t-10px relative"
            },
            [
              _vm._v("\n        廃棄する\n        "),
              _c(
                "div",
                {
                  staticClass:
                    "w-14px h-14px bg-white00 rounded-full absolute top-1/2 right-10px transform -translate-y-1/2 cursor-pointer",
                  on: { click: _vm.close }
                },
                [_vm._m(0)]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-full bg-white00 py-30px rounded-b-10px" },
            [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "flex justify-center items-center" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "block px-15px leading-loose text-white00 text-xs bg-grey04 rounded-20px mr-20px",
                    on: { click: _vm.submit }
                  },
                  [_vm._v("\n            廃棄する\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "block px-20px leading-loose text-white00 text-xs bg-grey04 rounded-20px cursor-pointer",
                    on: { click: _vm.close }
                  },
                  [_vm._v("\n            やめる\n          ")]
                )
              ])
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full h-full relative" }, [
      _c("span", {
        staticClass:
          "h-px w-10px bg-grey04 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 rotate-45"
      }),
      _vm._v(" "),
      _c("span", {
        staticClass:
          "h-px w-10px bg-grey04 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 -rotate-45"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-fit mx-auto mb-30px" }, [
      _c("p", { staticClass: "text-xs font-bold text-black01" }, [
        _vm._v("\n            本当に廃棄しますか？"),
        _c("br"),
        _vm._v("復旧することができません。\n          ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }