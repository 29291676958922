<template>
  <div class="modal" :class="{ shown: dialogData.shown }">
    <div class="modal__background" v-on:click="close"></div>
    <div class="modal__popup popup">
      <form class="form">
        <div class="popup__header bg-black01">
          <h3>{{ dialogData.title }}</h3>
          <button
            type="button"
            class="button popup__header__close"
            aria-label="Close"
            v-on:click="close"
          >
            <close></close>
          </button>
        </div>
        <div class="overflow-auto">
          <div class="popup__content">
            <div>
              <div
                v-for="(field, index) in dialogData.fields"
                :key="index"
                class="form-field"
              >
                <template v-if="field.type == 'text'">
                  <label :for="field.property" class="text-black01">{{ field.label }}</label>
                  <input
                    class="w-full"
                    :class="field.class"
                    type="text"
                    :id="`${field.property}`"
                    :name="`${field.property}`"
                    v-model="field.value"
                  />
                </template>
                <template v-else-if="field.type == 'select'">
                  <label :for="`${field.property}`" class="text-black01">{{ field.label }}</label>
                  <v-select
                    class=""
                    :class="field.class"
                    :id="field.property"
                    :name="field.property"
                    :options="field.options"
                    :reduce="(x) => x.value"
                    label="label"
                    v-model.number="field.value"
                  ></v-select>
                </template>
              </div>
            </div>
          </div>
          <div class="popup__footer">
            <input
              class="button bg-black01"
              type="button"
              :value="'クリア'"
              v-on:click="reset"
            />
            <input
              class="button bg-black01"
              type="button"
              :value="dialogData.submitLabel"
              v-on:click="submit"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialogData: Object,
  },
  data() {
    return {};
  },
  methods: {
    close: function () {
      this.$emit("close-dialog");
    },
    submit: function () {
      this.$emit("submit-search");
    },
    reset: function () {
      this.dialogData.fields.forEach((field) => {
        field.value = field.default;
      });
    },
  },
  mounted() {},
};
</script>
