<template>
  <div class="project-container container md:p-30px px-15px">
    <div class="container__header md:flex-row">
      <div
        class="container__header__block container__header__block--left"
      ></div>
      <div class="container__header__block">
        <div>
          <h1 class="md:text-lg text-sm text-black01">{{ "案件指示書" }}</h1>
        </div>
      </div>
      <div
        class="container__header__block container__header__block--right md:mt-0 mt-15px md:justify-end ml-auto"
      >
        <div class="header-buttons">
          <button
            class="button leading-none py-5px px-15px w-fit md:min-w-auto min-w-100px bg-black01 md:w-full m-0 md: text-xs"
            type="button"
            v-on:click="store(true)"
          >
            {{ "登録" }}
          </button>
        </div>
      </div>
    </div>
    <div class="container__content">
      <div>
        <div class="form project-form">
          <div class="project-form-upper md:flex">
            <div class="project-form-upper-left">
              <div class="form-field">
                <label>{{ "案件ID" }}</label>
                <span class="leading-loose-content text-xs">{{
                  project.friendly_id
                }}</span>
              </div>
              <div class="form-field">
                <label>{{ "作成日時" }}</label>
                <span class="text-xs leading-loose-content">{{
                  formatISODate(project.created_at, "yyyy-MM-dd HH:mm:ss")
                }}</span>
              </div>
              <div class="form-field">
                <label for="project_status">{{ "ステータス" }}</label>
                <div class="input-wrapper form-field-content text-xs w-90px">
                  <v-select
                    class="w-full"
                    id="project_manager"
                    v-bind:options="project_statuses"
                    v-bind:reduce="(x) => x.value"
                    v-model="project.status"
                    :searchable="true"
                  ></v-select>
                  <div
                    v-bind:class="{
                      hidden: errors.status.length == 0,
                    }"
                    v-text="errors.status.join('\n')"
                    class="message message--error message--input"
                  ></div>
                </div>
              </div>
              <div class="form-field">
                <label>{{ "バージョン" }}</label>
                <span class="form-field-content text-xs leading-loose">{{
                  project.version
                }}</span>
              </div>
              <div class="form-field">
                <label>{{ "ガント対象" }}</label>
                <div class="input-wrapper">
                  <div class="radio_buttons flex">
                    <div class="flex">
                      <input
                        class="w-1/2"
                        type="radio"
                        id="gantt_on"
                        name="gantt"
                        v-bind:value="true"
                        v-model="project.gantt"
                      />
                      <label
                        class="w-fit border-none projects_radio"
                        for="gantt_on"
                        >{{ "あり" }}</label
                      >
                    </div>
                    <div class="flex">
                      <input
                        class="w-1/2"
                        type="radio"
                        id="gantt_off"
                        name="gantt"
                        v-bind:value="false"
                        v-model="project.gantt"
                      />
                      <label
                        class="w-fit border-none projects_radio"
                        for="gantt_off"
                        >{{ "なし" }}</label
                      >
                    </div>
                  </div>
                  <div
                    v-bind:class="{
                      hidden: errors.gantt.length == 0,
                    }"
                    v-text="errors.gantt.join('\n')"
                    class="message message--error message--input"
                  ></div>
                </div>
              </div>
              <div class="form-field">
                <label for="project_type">{{ "ヘビー度" }}</label>
                <div class="input-wrapper form-field-content text-xs">
                  <v-select
                    id="priority"
                    v-bind:options="project_priorities"
                    v-bind:reduce="(x) => x.value"
                    v-model="project.priority"
                  ></v-select>
                  <div
                    v-bind:class="{ hidden: errors.priority.length == 0 }"
                    v-text="errors.priority.join('\n')"
                    class="message message--error message--input"
                  ></div>
                </div>
              </div>
            </div>
            <div class="project-form-upper-right">
              <div v-if="!isUserAffiliate" class="form-field">
                <label>{{ "請求ID" }}</label>
                <a
                  v-if="project.bill"
                  :href="buildBillUrl(project.bill)"
                  target="_blank"
                  class="form-field-content text-xs opacity decoration"
                  >{{ project.bill ? project.bill.friendly_id : "" }}</a
                >
              </div>
              <div class="form-field">
                <label for="project_manager">{{ "作成者" }}</label>
                <div class="input-wrapper form-field-content text-xs flex-grow">
                  <v-select
                    class="block w-full min-w-0 max-w-full"
                    id="project_manager"
                    v-bind:options="selectUserWithAffiliateOptions"
                    v-bind:reduce="(x) => x.value"
                    v-model="project.manager_id"
                  ></v-select>
                  <div
                    v-bind:class="{
                      hidden: errors.manager_id.length == 0,
                    }"
                    v-text="errors.manager_id.join('\n')"
                    class="message message--error message--input"
                  ></div>
                </div>
              </div>
              <div class="form-field">
                <label for="project_customer">{{ "クライアント" }}</label>
                <div class="input-wrapper form-field-content text-xs flex-grow">
                  <v-select
                    class="block w-full min-w-0 max-w-full"
                    id="project_customer"
                    v-bind:options="selectCustomerOptions"
                    v-bind:reduce="(x) => x.value"
                    v-model="project.customer_id"
                  ></v-select>
                  <div
                    v-bind:class="{
                      hidden: errors.customer_id.length == 0,
                    }"
                    v-text="errors.customer_id.join('\n')"
                    class="message message--error message--input"
                  ></div>
                </div>
              </div>
              <div v-if="!isUserAffiliate" class="form-field">
                <label for="project_contact">{{ "担当者名" }}</label>
                <div class="input-wrapper form-field-content text-xs flex-grow">
                  <v-select
                    class="block w-full min-w-0 max-w-full"
                    id="project_contact"
                    v-bind:options="selectContactOptions"
                    v-bind:reduce="(x) => x.value"
                    v-model="project.contact_id"
                  ></v-select>
                  <div
                    v-bind:class="{
                      hidden: errors.contact_id.length == 0,
                    }"
                    v-text="errors.contact_id.join('\n')"
                    class="message message--error message--input"
                  ></div>
                </div>
              </div>
              <div class="form-field">
                <label for="project_type">{{ "種別" }}</label>
                <div class="input-wrapper form-field-content text-xs">
                  <v-select
                    id="project_type"
                    v-bind:options="project_types"
                    v-bind:reduce="(x) => x.value"
                    v-model="project.type"
                  ></v-select>
                  <div
                    v-bind:class="{ hidden: errors.type.length == 0 }"
                    v-text="errors.type.join('\n')"
                    class="message message--error message--input"
                  ></div>
                </div>
              </div>
              <div class="form-field">
                <label for="project_start_date">{{ "開始日時" }}</label>
                <div class="input-wrapper">
                  <div class="flex">
                    <date-picker
                      class=""
                      v-model="project.start_date"
                      format="YYYY-MM-DD"
                      type="date"
                      value-type="format"
                    ></date-picker>
                  </div>
                  <div
                    v-bind:class="{
                      hidden:
                        errors.start_date.length == 0,
                    }"
                    v-text="
                      errors.start_date
                        .join('\n')
                    "
                    class="message message--error message--input"
                  ></div>
                </div>
              </div>
              <div class="form-field">
                <label for="project_due_date">{{ "期日時間" }}</label>
                <div class="input-wrapper">
                  <div class="flex">
                    <date-picker
                      class="w-3/5 pr-10px"
                      v-model="project.due_date"
                      format="YYYY-MM-DD"
                      type="date"
                      value-type="format"
                    ></date-picker>
                    <date-picker
                      class="w-2/5"
                      v-bind:class="{ hidden: project.type != 0 }"
                      v-model="project.due_time"
                      format="HH:mm"
                      type="time"
                      value-type="format"
                      :minuteStep="30"
                    ></date-picker>
                    <v-select
                      class="w-2/5"
                      id="project_due_time_period"
                      v-bind:class="{ hidden: project.type == 0 }"
                      v-bind:options="project_time_periods"
                      v-bind:reduce="(x) => x.value"
                      v-model="project.due_time_period"
                    ></v-select>
                  </div>
                  <div
                    v-bind:class="{
                      hidden:
                        errors.due_date.length == 0 &&
                        errors.due_time.length == 0 &&
                        errors.due_time_period.length == 0,
                    }"
                    v-text="
                      errors.due_date
                        .concat(errors.due_time)
                        .concat(errors.due_time_period)
                        .join('\n')
                    "
                    class="message message--error message--input"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="project-form-lower">
            <div
              class="form-field full-width mb-20px flex md:flex-row flex-col"
            >
              <label for="project_name" class="mb-10px">{{ "案件名" }}</label>
              <div class="input-wrapper form-field-content">
                <input
                  type="text"
                  id="project_name"
                  class="project_name text-xs"
                  v-model="project.name"
                />
                <div
                  v-bind:class="{ hidden: errors.name.length == 0 }"
                  v-text="errors.name.join('\n')"
                  class="message message--error message--input"
                ></div>
              </div>
            </div>

            <!-- ステータス -->
            <div class="">
              <div class="TODO flex flex-wrap">
                <!-- 出力ステータス -->
                <div
                  class="TODO flex item, vertical orientation md:w-1/2 w-full pr-30px mb-10px -ml-5px"
                >
                  <div class="TODO on-off flex-shrink-0">
                    <div class="form-field">
                      <div class="input-wrapper form-field-content w-fit">
                        <div class="radio_buttons">
                          <div class="flex w-70px pb-8px">
                            <input
                              class="w-1/2"
                              type="radio"
                              id="output_enabled_on"
                              name="output_enabled"
                              v-bind:value="true"
                              v-model="project.output_enabled"
                            />
                            <label
                              class="w-fit border-none projects_radio"
                              for="output_enabled_on"
                              >{{ "ON" }}</label
                            >
                          </div>
                          <div class="flex w-70px">
                            <input
                              class="w-1/2"
                              type="radio"
                              id="output_enabled_off"
                              name="output_enabled"
                              v-bind:value="false"
                              v-model="project.output_enabled"
                            />
                            <label
                              class="w-fit border-none projects_radio"
                              for="output_enabled_off"
                              >{{ "OFF" }}</label
                            >
                          </div>
                        </div>
                        <div
                          v-bind:class="{
                            hidden: errors.output_enabled.length == 0,
                          }"
                          v-text="errors.output_enabled.join('\n')"
                          class="message message--error message--input"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="TODO inputs w-full md:pr-0 pr-30px">
                    <div class="form-field">
                      <label for="output_status">{{ "出力ステータス" }}</label>
                      <div class="input-wrapper form-field-content flex-grow">
                        <v-select
                          class="block w-full min-w-0 max-w-full"
                          id="output_status"
                          v-bind:options="project_manager_statuses"
                          v-bind:reduce="(x) => x.value"
                          v-model="project.output_status"
                          v-bind:disabled="
                            project.output_enabled == 0 ||
                            do_not_notify == false
                          "
                        ></v-select>
                        <div
                          v-bind:class="{
                            hidden: errors.output_status.length == 0,
                          }"
                          v-text="errors.output_status.join('\n')"
                          class="message message--error message--input"
                        ></div>
                      </div>
                    </div>
                    <div class="form-field">
                      <label for="output_manager">{{ "出力担当" }}</label>
                      <div class="input-wrapper form-field-content flex-grow">
                        <v-select
                          class="block w-full min-w-0 max-w-full"
                          id="output_manager"
                          v-bind:options="selectUserOutputDepartment"
                          v-bind:reduce="(x) => x.value"
                          v-model="project.output_manager_id"
                          v-bind:disabled="project.output_enabled == 0"
                        ></v-select>
                        <div
                          v-bind:class="{
                            hidden: errors.output_manager_id.length == 0,
                          }"
                          v-text="errors.output_manager_id.join('\n')"
                          class="message message--error message--input"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 技術ステータス -->
                <div
                  class="TODO flex item, vertical orientation md:w-1/2 w-full pr-30px mb-10px -ml-5px"
                >
                  <div class="TODO on-off flex-shrink-0">
                    <div class="form-field">
                      <div class="input-wrapper form-field-content w-fit">
                        <div class="radio_buttons">
                          <div class="flex w-70px pb-8px">
                            <input
                              class="w-1/2"
                              type="radio"
                              id="technical_enabled_on"
                              name="technical_enabled"
                              v-bind:value="true"
                              v-model="project.technical_enabled"
                            />
                            <label
                              class="w-fit border-none projects_radio"
                              for="technical_enabled_on"
                              >{{ "ON" }}</label
                            >
                          </div>
                          <div class="flex w-70px">
                            <input
                              class="w-1/2"
                              type="radio"
                              id="technical_enabled_off"
                              name="technical_enabled"
                              v-bind:value="false"
                              v-model="project.technical_enabled"
                            />
                            <label
                              class="w-fit border-none projects_radio"
                              for="technical_enabled_off"
                              >{{ "OFF" }}</label
                            >
                          </div>
                        </div>
                        <div
                          v-bind:class="{
                            hidden: errors.technical_enabled.length == 0,
                          }"
                          v-text="errors.technical_enabled.join('\n')"
                          class="message message--error message--input"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="TODO inputs w-full md:pr-0 pr-30px">
                    <div class="form-field">
                      <label for="technical_status">{{
                        "技術ステータス"
                      }}</label>
                      <div class="input-wrapper form-field-content flex-grow">
                        <v-select
                          class="block w-full min-w-0 max-w-full"
                          id="technical_status"
                          v-bind:options="project_manager_statuses"
                          v-bind:reduce="(x) => x.value"
                          v-model="project.technical_status"
                          v-bind:disabled="
                            project.technical_enabled == 0 ||
                            do_not_notify == false
                          "
                        ></v-select>
                        <div
                          v-bind:class="{
                            hidden: errors.technical_status.length == 0,
                          }"
                          v-text="errors.technical_status.join('\n')"
                          class="message message--error message--input"
                        ></div>
                      </div>
                    </div>
                    <div class="form-field">
                      <label for="techincal_manager">{{ "技術担当" }}</label>
                      <div class="input-wrapper form-field-content flex-grow">
                        <v-select
                          class="block w-full min-w-0 max-w-full"
                          id="techincal_manager"
                          v-bind:options="selectUserTechnicalDepartment"
                          v-bind:reduce="(x) => x.value"
                          v-model="project.technical_manager_id"
                          v-bind:disabled="project.technical_enabled == 0"
                        ></v-select>
                        <div
                          v-bind:class="{
                            hidden: errors.technical_manager_id.length == 0,
                          }"
                          v-text="errors.technical_manager_id.join('\n')"
                          class="message message--error message--input"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 加工ステータス -->
                <div
                  class="TODO flex item, vertical orientation md:w-1/2 w-full pr-30px mb-10px -ml-5px"
                >
                  <div class="TODO on-off flex-shrink-0">
                    <div class="form-field">
                      <div class="input-wrapper form-field-content w-fit">
                        <div class="radio_buttons">
                          <div class="flex w-70px pb-8px">
                            <input
                              class="w-1/2"
                              type="radio"
                              id="processing_enabled_on"
                              name="processing_enabled"
                              v-bind:value="true"
                              v-model="project.processing_enabled"
                            />
                            <label
                              class="w-fit border-none projects_radio"
                              for="processing_enabled_on"
                              >{{ "ON" }}</label
                            >
                          </div>
                          <div class="flex w-70px">
                            <input
                              class="w-1/2"
                              type="radio"
                              id="processing_enabled_off"
                              name="processing_enabled"
                              v-bind:value="false"
                              v-model="project.processing_enabled"
                            />
                            <label
                              class="w-fit border-none projects_radio"
                              for="processing_enabled_off"
                              >{{ "OFF" }}</label
                            >
                          </div>
                        </div>
                        <div
                          v-bind:class="{
                            hidden: errors.processing_enabled.length == 0,
                          }"
                          v-text="errors.processing_enabled.join('\n')"
                          class="message message--error message--input"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="TODO inputs w-full md:pr-0 pr-30px">
                    <div class="form-field">
                      <label for="processing_status">{{
                        "加工ステータス"
                      }}</label>
                      <div class="input-wrapper form-field-content flex-grow">
                        <v-select
                          class="block w-full min-w-0 max-w-full"
                          id="processing_status"
                          v-bind:options="project_manager_statuses"
                          v-bind:reduce="(x) => x.value"
                          v-model="project.processing_status"
                          v-bind:disabled="
                            project.processing_enabled == 0 ||
                            do_not_notify == false
                          "
                        ></v-select>
                        <div
                          v-bind:class="{
                            hidden: errors.processing_status.length == 0,
                          }"
                          v-text="errors.processing_status.join('\n')"
                          class="message message--error message--input"
                        ></div>
                      </div>
                    </div>
                    <div class="form-field">
                      <label for="processing_manager">{{ "加工担当" }}</label>
                      <div class="input-wrapper form-field-content flex-grow">
                        <v-select
                          class="block w-full min-w-0 max-w-full"
                          id="processing_manager"
                          v-bind:options="selectUserProcessingDepartment"
                          v-bind:reduce="(x) => x.value"
                          v-model="project.processing_manager_id"
                          v-bind:disabled="project.processing_enabled == 0"
                        ></v-select>
                        <div
                          v-bind:class="{
                            hidden: errors.processing_manager_id.length == 0,
                          }"
                          v-text="errors.processing_manager_id.join('\n')"
                          class="message message--error message--input"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /ステータス -->

            <div class="form-field full-width flex md:flex-row flex-col">
              <label for="delivery_instructions" class="mb-10px">{{
                "配送指示"
              }}</label>
              <div class="input-wrapper form-field-content">
                <input
                  type="text"
                  id="delivery_instructions"
                  class="text-xs"
                  v-model="project.delivery_instructions"
                />
                <div
                  v-bind:class="{
                    hidden: errors.delivery_instructions.length == 0,
                  }"
                  v-text="errors.delivery_instructions.join('\n')"
                  class="message message--error message--input"
                ></div>
              </div>
            </div>
            <div class="form-field mb-15px">
              <label>{{ "返却資料" }}</label>
              <div class="input-wrapper">
                <div class="radio_buttons flex">
                  <div class="flex">
                    <input
                      class="w-1/2"
                      type="radio"
                      id="return_materials_on"
                      name="return_materials"
                      v-bind:value="true"
                      v-model="project.return_materials"
                    />
                    <label
                      class="w-fit border-none projects_radio"
                      for="return_materials_on"
                      >{{ "あり" }}</label
                    >
                  </div>
                  <div class="flex">
                    <input
                      class="w-1/2"
                      type="radio"
                      id="return_materials_off"
                      name="return_materials"
                      v-bind:value="false"
                      v-model="project.return_materials"
                    />
                    <label
                      class="w-fit border-none projects_radio"
                      for="return_materials_off"
                      >{{ "なし" }}</label
                    >
                  </div>
                </div>
                <div
                  v-bind:class="{
                    hidden: errors.return_materials.length == 0,
                  }"
                  v-text="errors.return_materials.join('\n')"
                  class="message message--error message--input"
                ></div>
              </div>
            </div>
            <div class="form-field full-width flex md:flex-row flex-col">
              <label for="comment1" class="mb-10px">{{ "コメント" }}</label>
              <div class="input-wrapper form-field-content mb-30px">
                <textarea
                  class="py-8px pl-10px pr-15px"
                  type="text"
                  id="comment1"
                  v-model="project.comment1"
                  style="
                    font-size: 12px;
                    min-width: 0;
                    height: 5rem;
                    border: 1px solid;
                    border-radius: 12px;
                    line-height: 1;
                    margin-bottom: 10px;
                  "
                ></textarea>
                <div
                  v-bind:class="{ hidden: errors.comment1.length == 0 }"
                  v-text="errors.comment1.join('\n')"
                  class="message message--error message--input"
                ></div>
                <textarea
                  type="text"
                  id="comment2"
                  v-model="project.comment2"
                  class="text-xs text-red02 py-8px pl-10px pr-15px"
                  style="
                    min-width: 0;
                    height: 5rem;
                    border: 1px solid;
                    border-radius: 12px;
                    line-height: 1;
                  "
                ></textarea>
                <div
                  v-bind:class="{ hidden: errors.comment2.length == 0 }"
                  v-text="errors.comment2.join('\n')"
                  class="message message--error message--input"
                ></div>
              </div>
            </div>
            <div class="form-field md:flex-row flex flex-col">
              <label for="files" class="mb-15px">{{ "添付PDF" }}</label>
              <div class="md:w-3/5">
                <div>
                  <div>
                    <ul>
                      <li v-for="(file, index) in files" :key="index">
                        <div class="flex items-center w-full mb-15px">
                          <div
                            class="flex flex-col md:mx-10px mr-10px md:w-auto"
                          >
                            <button
                              class="pb-5px md:mx-auto border-none"
                              type="button"
                              v-on:click="moveFileUp(index)"
                            >
                              <img
                                class="border-none"
                                src="/images/svg/projects/file_move_up.svg"
                                :alt="`move ${file.filename} up`"
                              />
                            </button>
                            <button
                              class="pt-5px md:mx-auto"
                              type="button"
                              v-on:click="moveFileDown(index)"
                            >
                              <img
                                src="/images/svg/projects/file_move_down.svg"
                                :alt="`move ${file.filename} down`"
                              />
                            </button>
                          </div>
                          <span class="w-fit mr-15px">{{ index + 1 }}</span>
                          <br />
                          <span
                            class="block w-1/2 text-xs whitespace-no-wrap overflow-x-auto md:mr-50px mr-30px"
                            >{{ file.filename }}</span
                          >
                          <br />
                          <button
                            class="mr-15px"
                            type="button"
                            v-on:click="deleteFile(index)"
                          >
                            <img
                              class="mx-auto"
                              src="/images/svg/projects/file_delete.svg"
                              :alt="`delete ${file.filename}`"
                            />
                          </button>
                          <input
                            type="file"
                            class="border-none h-auto hidden"
                            accept="application/pdf"
                            :id="`replace-file-${index}`"
                            :name="`replace-file-${index}`"
                            v-on:change="replaceFile($event, index)"
                          />
                          <label
                            :for="`replace-file-${index}`"
                            class="border-none cursor-pointer flex items-center w-auto"
                          >
                            <img
                              src="/images/svg/projects/file_replace.svg"
                              :alt="`replace ${file.filename}`"
                            />
                          </label>
                        </div>
                        <div
                          v-bind:class="{
                            hidden: file.errors.length == 0,
                          }"
                          v-text="file.errors.join('\n')"
                          class="message message--error message--input"
                        ></div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="input-wrapper text-xs">
                      <div>
                        <input
                          class="border-none h-auto hidden"
                          type="file"
                          accept="application/pdf"
                          id="file"
                          name="file"
                          v-on:change="uploadFile($event)"
                        />
                        <label
                          for="file"
                          class="border-none cursor-pointer text-blue02"
                        >
                          <img
                            src="/images/svg/projects/file_uploads.svg"
                            alt="追加アップロード"
                            class="inline pr-5px"
                          />
                          {{ "追加アップロード" }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-field full-width flex-col">
              <label
                for="update_comment"
                class="border-none w-full text-left mb-10px"
                >{{ "※変更コメントを入力してください" }}</label
              >
              <div class="input-wrapper form-field-content text-xs">
                <textarea
                  class="bg-grey00 py-8px pl-10px pr-15px"
                  style="border-radius: 10px"
                  type="text"
                  id="update_comment"
                  v-model="update_comment"
                ></textarea>
                <div
                  v-bind:class="{
                    hidden: errors.update_comment.length == 0,
                  }"
                  v-text="errors.update_comment.join('\n')"
                  class="message message--error message--input"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container__footer mt-0">
      <div
        class="container__footer__block container__footer__block--left"
      ></div>
      <div class="container__footer__block">
        <div class="w-full">
          <div class="footer-buttons flex-row mx-auto mb-20px">
            <input
              type="checkbox"
              id="do_not_notify"
              class="my-auto"
              style="transform: scale(1.5)"
              v-model="do_not_notify"
            />
            <label for="do_not_notify" class="pl-10px text-xs">{{
              "PDFダウンロードを全体に通知しない"
            }}</label>
          </div>
          <div class="footer-buttons flex-row">
            <button
              class="button bg-black01 text-xs w-80px py-5px px-0"
              type="button"
              v-on:click="store()"
            >
              {{ "登録" }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="container__footer__block container__footer__block--right"
      ></div>
    </div>
    <projects-modal-delete-pdf
      :class="{ hidden: deletePDFDialogData.hidden }"
      :index="deletePDFDialogData.index"
      v-on:close="deleteFileSubmit"
      v-on:submit="deleteFileSubmit"
    ></projects-modal-delete-pdf>
  </div>
</template>

<script>
import ProjectsModalDeletePDF from "./ProjectsModalDeletePdf.vue";
export default {
  props: {
    customers: Array,
    users: Array,
    urls: Object,
    project_statuses: Array,
    project_types: Array,
    project_manager_statuses: Array,
    project_time_periods: Array,
    project_priorities: Array,
    initial_project: Object,
    user: Object,
  },
  data() {
    return {
      contacts: [],
      deletePDFDialogData: {
        hidden: true,
        index: -1,
      },
      project: {
        friendly_id: this.initial_project.friendly_id,
        created_at: this.initial_project.created_at,
        status: this.initial_project.status,
        version: this.initial_project.version,
        bill_id: this.initial_project.bill_id,
        bill: this.initial_project.bill,
        manager_id: this.initial_project.manager_id,
        customer_id: this.initial_project.customer_id,
        contact_id: this.initial_project.contact_id,
        type: this.initial_project.type,
        start_date: this.initial_project.start_date,
        due_date: this.initial_project.due_date,
        due_time: this.initial_project.due_time,
        due_time_period: this.initial_project.due_time_period,
        name: this.initial_project.name,
        output_enabled: this.initial_project.output_enabled,
        output_status: this.initial_project.output_status,
        output_manager_id: this.initial_project.output_manager_id,
        technical_enabled: this.initial_project.technical_enabled,
        technical_status: this.initial_project.technical_status,
        technical_manager_id: this.initial_project.technical_manager_id,
        processing_enabled: this.initial_project.processing_enabled,
        processing_status: this.initial_project.processing_status,
        processing_manager_id: this.initial_project.processing_manager_id,
        delivery_instructions: this.initial_project.delivery_instructions,
        return_materials: this.initial_project.return_materials,
        comment1: this.initial_project.comment1,
        comment2: this.initial_project.comment2,
        project_files: this.initial_project.project_files,
        gantt: this.initial_project.gantt,
        priority: this.initial_project.priority,
      },
      update_comment: "",
      do_not_notify: false,
      files: [],
      deleted_files: [],
      errors: {
        status: [],
        manager_id: [],
        customer_id: [],
        contact_id: [],
        type: [],
        start_date: [],
        due_date: [],
        due_time: [],
        due_time_period: [],
        name: [],
        output_enabled: [],
        output_status: [],
        output_manager_id: [],
        technical_enabled: [],
        technical_status: [],
        technical_manager_id: [],
        processing_enabled: [],
        processing_status: [],
        processing_manager_id: [],
        delivery_instructions: [],
        return_materials: [],
        comment1: [],
        comment2: [],
        update_comment: [],
        do_not_notify: [],
        files: [],
        gantt: [],
        priority: [],
      },
    };
  },
  computed: {
    isUserAffiliate: function () {
      if (this.user.affiliate) {
        return true;
      }
      return false;
    },
    selectUserOptions() {
      return this.users.map((x) => {
        return {
          value: x.id,
          label: this.formatUserName(x, false),
        };
      });
    },
    selectUserOutputDepartment() {
      var options = this.users
        .filter((x) => x.department == 1)
        .map((x) => {
          return {
            value: x.id,
            label: this.formatUserName(x, true),
          };
        });

      if (this.initial_project.outputManager && !options.some(x => x.value == this.initial_project.outputManager.id)) {
        options.push({
          value: this.initial_project.outputManager.id,
          label: this.formatUserName(this.initial_project.outputManager, true),
        });
      }

      options.unshift({ value: null, label: "　" });

      return options;
    },
    selectUserTechnicalDepartment() {
      var options = this.users
        .filter((x) => x.department == 2)
        .map((x) => {
          return {
            value: x.id,
            label: this.formatUserName(x, true),
          };
        });

      if (this.initial_project.technicalManager && !options.some(x => x.value == this.initial_project.technicalManager.id)) {
        options.push({
          value: this.initial_project.technicalManager.id,
          label: this.formatUserName(this.initial_project.technicalManager, true),
        });
      }

      options.unshift({ value: null, label: "　" });

      return options;
    },
    selectUserProcessingDepartment() {
      var options = this.users
        .filter((x) => x.department == 3)
        .map((x) => {
          return {
            value: x.id,
            label: this.formatUserName(x, true),
          };
        });

      if (this.initial_project.processingManager && !options.some(x => x.value == this.initial_project.processingManager.id)) {
        options.push({
          value: this.initial_project.processingManager.id,
          label: this.formatUserName(this.initial_project.processingManager, true),
        });
      }

      options.unshift({ value: null, label: "　" });

      return options;
    },
    selectUserWithAffiliateOptions() {
      return this.users.map((x) => {
        return {
          value: x.id,
          label: this.formatUserName(x, true),
        };
      });
    },
    selectCustomerOptions() {
      return this.customers.map((x) => {
        return {
          value: x.id,
          label: x.name,
        };
      });
    },
    selectContactOptions() {
      let result = [
        {
          value: null,
          label: '　',
        }
      ];
      this.contacts.forEach((x) => {
        let text = '';
        if (x.department != '') {
            text += x.department;
        }
        if (x.department != '' && x.name != '') {
            text += ' - ';
        }
        if (x.name != '') {
            text += x.name;
        }
        let item = {
            value: x.id,
            label: text,
        }
        result.push(item);
      });
      return result;
    },
  },
  watch: {
    'project.customer_id': function(newValue) {
      this.refresh_contacts();
    },
  },
  methods: {
    buildBillUrl(bill) {
      if (bill) {
        return this.urls.billShow.replace(/__BILL_ID__/, bill.id);
      }
      return "";
    },
    formatUserName(user, affiliate = false) {
      var displayName = user.name;
      if (affiliate && user.affiliate) {
        displayName += ` [${user.affiliate.name}]`;
      }
      return displayName;
    },
    getFileObject() {
      return {
        id: -1,
        filename: "",
        index: -1,
        object: null,
        errors: [],
      };
    },
    uploadFile(event) {
      if (event.target.files.length > 0) {
        const fileObject = event.target.files[0];
        const file = this.getFileObject();
        file.filename = fileObject.name;
        file.object = fileObject;
        this.files.push(file);
      }
    },
    replaceFile(event, index) {
      if (event.target.files.length > 0) {
        const fileObject = event.target.files[0];
        const file = this.files[index];
        file.filename = fileObject.name;
        file.object = fileObject;
      }
    },
    deleteFile(index) {
      this.deletePDFDialogData.index = index;
      this.deletePDFDialogData.hidden = false;
    },
    deleteFileSubmit(index) {
      if (index != null) {
        const file = this.files[index];
        if (file.id > 0) {
          this.deleted_files.push(file.id);
        }
        this.files.splice(index, 1);
      }
      this.deletePDFDialogData.hidden = true;
    },
    moveFileUp(index) {
      if (index > 0) {
        this.files.splice(index - 1, 0, this.files.splice(index, 1)[0]);
      }
    },
    moveFileDown(index) {
      if (index < this.files.length - 1) {
        this.files.splice(index + 1, 0, this.files.splice(index, 1)[0]);
      }
    },
    clearErrors() {
      for (const key in this.errors) {
        this.errors[key] = [];
      }
      for (const key in this.files) {
        this.files[key].errors = [];
      }
    },
    refresh_contacts() {
      this.clearErrors();

      this.contacts = [];

      if (this.project.customer_id != null) {
        axios
        .get(this.urls.show_contacts.replace('__CUSTOMER__', this.project.customer_id))
        .then((response) => {
          console.log(response);
          var data = JSON.parse(response.data);
          try {
            this.contacts = data.contacts;
          } catch (err) {
            this.$emit("add-error", "Invalid server response while retrieving customer contacts");
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            console.log(error.response);
            this.$emit("add-error", "Could not retrieve customer contacts");
          }
        });
      }
    },
    store() {
      this.clearErrors();
      this.$emit("clear-errors");

      var data = {
        update_comment: this.update_comment,
        do_not_notify: this.do_not_notify,
        files: [],
        deleted_files: this.deleted_files,
        project: {
          status: this.project.status,
          manager_id: this.project.manager_id,
          customer_id: this.project.customer_id,
          contact_id: this.project.contact_id,
          type: this.project.type,
          start_date: this.project.start_date,
          due_date: this.project.due_date,
          due_time: this.project.due_time,
          due_time_period: this.project.due_time_period,
          name: this.project.name,
          output_enabled: this.project.output_enabled,
          output_status: this.project.output_status,
          output_manager_id: this.project.output_manager_id,
          technical_enabled: this.project.technical_enabled,
          technical_status: this.project.technical_status,
          technical_manager_id: this.project.technical_manager_id,
          processing_enabled: this.project.processing_enabled,
          processing_status: this.project.processing_status,
          processing_manager_id: this.project.processing_manager_id,
          delivery_instructions: this.project.delivery_instructions,
          return_materials: this.project.return_materials,
          comment1: this.project.comment1,
          comment2: this.project.comment2,
          gantt: this.project.gantt,
          priority: this.project.priority,
        },
      };

      const formData = new FormData();

      var currentUploadFileCount = 0;
      for (var i = 0; i < this.files.length; i++) {
        const file = this.files[i];
        if (file.object) {
          formData.append(
            `uploaded_files[${currentUploadFileCount}]`,
            file.object
          );
          file.index = currentUploadFileCount++;
        }
      }

      this.files.forEach((file) => {
        data.files.push({
          id: file.id,
          filename: file.filename,
          index: file.index,
        });
      });

      formData.set("data", JSON.stringify(data));

      axios
        .post(this.urls.update, formData, {
          headers: {
            Accepts: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          var data = JSON.parse(response.data);
          try {
            window.location.href = data.redirect;
          } catch (err) {
            this.$emit("add-error", "Invalid server response");
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            try {
              var data = JSON.parse(error.response.data);
              data.errors.forEach((error) => {
                if (typeof error.messages == "string") {
                  this.$emit("add-error", error.messages);
                } else {
                  error.messages.forEach((message) => {
                    if (error.key.startsWith("files.")) {
                      const key_index = parseInt(error.key.split(".")[1]);
                      if (key_index < this.files.length) {
                        this.files[key_index].errors.push(message);
                      } else {
                        this.$emit("add-error", message);
                      }
                    } else if (
                      error.key &&
                      this.errors.hasOwnProperty(error.key)
                    ) {
                      this.errors[error.key].push(message);
                    } else {
                      this.$emit("add-error", message);
                    }
                  });
                }
              });
            } catch (err) {
              console.log(error.response.data);
              this.$emit("add-error", `Could not save project`);
            }
          }
        });
    },
  },
  mounted() {
    this.project.project_files.forEach((project_file) => {
      const file = this.getFileObject();
      file.id = project_file.id;
      file.filename = project_file.filename;
      this.files.push(file);
    });
    this.refresh_contacts();
  },
};
</script>
