<template>
  <div class="c-projects">
    <div v-if="totalPages >= 2" class="pagination-wrapper">
      <ul class="pagination">
        <li
          v-for="(link, index) in links"
          :key="index"
          :class="{ disabled: !link.url, active: link.active }"
        >
          <a :href="link.url">
            <span v-if="index === 0 || index === links.length - 1" v-html="link.label"></span>
            <span v-else>{{ link.label }}</span>
          </a>
        </li>
      </ul>

      <div class="pagination__input">
        <input v-model.number="page" v-on:change="navigateToPage" /><span
          >&nbsp;/&nbsp;{{ this.totalPages }}</span
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.pagination span,
.pagination a{
  color: #323232 !important;
}
.pagination .active span{
  color: #00A0FF !important;
}
.pagination__input input{
  border-color: #00A0FF !important;
}

</style>

<script>
export default {
  props: {
    links: Array,
    totalPages: Number,
  },
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    navigateToPage: function () {
      var params = new URLSearchParams(window.location.search);
      params.set("page", this.page);
      window.location.search = params.toString();
    },
  },
  mounted: function () {
    var params = new URLSearchParams(window.location.search);
    if (params.has("page")) {
      this.page = parseInt(params.get("page"));
    }
  },
};
</script>
