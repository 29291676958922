var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-projects" }, [
    _vm.totalPages >= 2
      ? _c("div", { staticClass: "pagination-wrapper" }, [
          _c(
            "ul",
            { staticClass: "pagination" },
            _vm._l(_vm.links, function(link, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: { disabled: !link.url, active: link.active }
                },
                [
                  _c("a", { attrs: { href: link.url } }, [
                    index === 0 || index === _vm.links.length - 1
                      ? _c("span", {
                          domProps: { innerHTML: _vm._s(link.label) }
                        })
                      : _c("span", [_vm._v(_vm._s(link.label))])
                  ])
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pagination__input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.page,
                  expression: "page",
                  modifiers: { number: true }
                }
              ],
              domProps: { value: _vm.page },
              on: {
                change: _vm.navigateToPage,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.page = _vm._n($event.target.value)
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _c("span", [_vm._v(" / " + _vm._s(this.totalPages))])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }