var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "\n    md:w-1/3\n    xs:w-1/2\n    w-full\n    xs:min-w-160px\n    md:min-w-220px\n    lg:max-w-220px\n    md:px-10px\n    px-7.5px\n    md:pb-20px\n    pb-15px\n  "
    },
    [
      _c(
        "div",
        { staticClass: "w-full relative overflow-hidden shadow-lg" },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show,
                    expression: "show"
                  }
                ],
                staticClass:
                  "\n          border-4\n          rounded-10px\n          w-full\n          h-full\n          absolute\n          top-1/2\n          left-1/2\n          transform\n          -translate-y-1/2 -translate-x-1/2\n          z-10\n        ",
                staticStyle: { "background-color": "rgba(50, 50, 50, 0.9)" }
              },
              [
                _c("div", { staticClass: "w-full h-full relative" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "\n              absolute\n              top-1/2\n              left-1/2\n              transform\n              -translate-y-1/2 -translate-x-1/2\n            "
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "\n                js-toggle-download-modal\n                block\n                text-base text-white00\n                font-light\n                cursor-pointer\n                mb-20px\n              ",
                          attrs: { id: "projects-download" },
                          on: { click: _vm.openModalOverviewDownload }
                        },
                        [_vm._v("\n              ダウンロード\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "block text-base text-white00 font-light cursor-pointer",
                          attrs: { id: "projects-complate" },
                          on: { click: _vm.openModalOverviewComplete }
                        },
                        [_vm._v("\n              作業完了\n            ")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "w-20px h-20px absolute top-10px right-10px",
                      on: {
                        click: function($event) {
                          _vm.show = !_vm.show
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "relative w-full h-full cursor-pointer"
                        },
                        [
                          _c("div", {
                            staticClass:
                              "\n                  bg-white00\n                  h-2px\n                  w-full\n                  absolute\n                  top-1/2\n                  left-1/2\n                  transform\n                  -translate-y-1/2 -translate-x-1/2\n                  rotate-45\n                "
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "\n                  bg-white00\n                  h-2px\n                  w-full\n                  absolute\n                  top-1/2\n                  left-1/2\n                  transform\n                  -translate-y-1/2 -translate-x-1/2\n                  -rotate-45\n                "
                          })
                        ]
                      )
                    ]
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "absolute top-0 left-0 w-full h-full border-4 rounded-10px p-10px",
              class: ["status-border-" + _vm.project.progression]
            },
            [
              _c(
                "div",
                { staticClass: "flex justify-between sm:mb-20px mb-10px" },
                [
                  _c("div", { staticClass: "flex items-start" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "\n              processing-manager\n              sm:text-xs\n              text-3xs\n              sm:leading-loose\n              leading-normal\n              sm:w-2em\n              w-1.5em\n              mr-5px\n            ",
                        class: {
                          "manager-disabled":
                            _vm.project.processing_enabled == false,
                          "manager-downloaded":
                            _vm.project.processing_status == 1 ||
                            _vm.project.processing_status == 2
                        }
                      },
                      [_vm._v("\n            " + _vm._s("加") + "\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "\n              output-manager\n              sm:text-xs\n              text-3xs\n              sm:leading-loose\n              leading-normal\n              sm:w-2em\n              w-1.5em\n              mr-5px\n            ",
                        class: {
                          "manager-disabled":
                            _vm.project.output_enabled == false,
                          "manager-downloaded":
                            _vm.project.output_status == 1 ||
                            _vm.project.output_status == 2
                        }
                      },
                      [_vm._v("\n            " + _vm._s("出") + "\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "\n              technical-manager\n              sm:text-xs\n              text-3xs\n              sm:leading-loose\n              leading-normal\n              sm:w-2em\n              w-1.5em\n              mr-5px\n            ",
                        class: {
                          "manager-disabled":
                            _vm.project.technical_enabled == false,
                          "manager-downloaded":
                            _vm.project.technical_status == 1 ||
                            _vm.project.technical_status == 2
                        }
                      },
                      [_vm._v("\n            " + _vm._s("技") + "\n          ")]
                    ),
                    _vm._v(" "),
                    _vm.project.affiliate_authored
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "\n              affiliate-authored\n              sm:text-xs\n              text-3xs\n              sm:leading-loose\n              leading-normal\n              sm:w-2em\n              w-1.5em\n            "
                          },
                          [
                            _vm._v(
                              "\n            " + _vm._s("外") + "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "\n            block\n            sm:w-20px\n            w-16px\n            sm:h-16px\n            h-14px\n            relative\n            cursor-pointer\n          ",
                      on: {
                        click: function($event) {
                          _vm.show = !_vm.show
                        }
                      }
                    },
                    [
                      _c("span", {
                        staticClass:
                          "\n              h-2px\n              w-full\n              bg-black01\n              absolute\n              top-0\n              left-1/2\n              transform\n              -translate-x-1/2\n            "
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass:
                          "\n              h-2px\n              w-full\n              bg-black01\n              absolute\n              top-1/2\n              left-1/2\n              transform\n              -translate-y-1/2 -translate-x-1/2\n            "
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass:
                          "\n              h-2px\n              w-full\n              bg-black01\n              absolute\n              bottom-0\n              left-1/2\n              transform\n              -translate-x-1/2\n            "
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                { attrs: { href: "/projects/" + _vm.project.id + "/show" } },
                [
                  _c("div", { staticClass: "w-full relative" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "\n              absolute\n              top-0\n              left-0\n              sm:text-base\n              text-xs text-black01\n              font-bold\n              sm:h-45px\n              h-35px\n              mb-20px\n              overflow-ellipsis overflow-hidden\n            "
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.project.name) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "pt-2/5" })
                  ]),
                  _vm._v(" "),
                  _c("hr", {
                    staticClass: "border-t-2 border-dashed border-grey03"
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "pt-5px" }, [
                    _c(
                      "div",
                      { staticClass: "flex items-center justify-between" },
                      [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "sm:text-base text-xs text-black01 font-bold relative"
                            },
                            [
                              _c("span", {
                                staticClass:
                                  "\n                    w-20px\n                    h-20px\n                    rounded-20px\n                    absolute\n                    top-1/2\n                    left-1/2\n                    transform\n                    -translate-y-1/2 -translate-x-1/2\n                  ",
                                class: [_vm.amBackgroundColor]
                              }),
                              _vm._v("\n                AM\n              ")
                            ]
                          ),
                          _vm._v("\n              ·\n              "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "sm:text-base text-xs text-black01 font-bold relative"
                            },
                            [
                              _c("span", {
                                staticClass:
                                  "\n                    w-20px\n                    h-20px\n                    rounded-20px\n                    absolute\n                    top-1/2\n                    left-1/2\n                    transform\n                    -translate-y-1/2 -translate-x-1/2\n                  ",
                                class: [_vm.pmBackgroundColor]
                              }),
                              _vm._v("\n                PM\n              ")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "\n                sm:text-base\n                text-xs text-white00\n                font-bold\n                px-10px\n                py-5px\n                rounded-20px\n              ",
                            class: ["type-bg-" + _vm.project.type]
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.project.type_string) +
                                "\n            "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "sm:text-base text-xs text-black01 font-bold"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.formatISODate(
                                _vm.project.due_date,
                                "MMMdo EEEEE"
                              )
                            ) +
                            "\n            " +
                            _vm._s(
                              _vm.project.type == 0
                                ? _vm.formatISODate(
                                    this.parseToISOString(
                                      this.project.due_time,
                                      "HH:mm",
                                      new Date()
                                    ),
                                    "a HH:mm",
                                    "en"
                                  )
                                : _vm.project.due_time_period == 0
                                ? "AM"
                                : "PM"
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pt-full" })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }