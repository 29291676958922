<template>
  <div class="w-full h-full fixed top-0 left-0 z-20">
    <div class="w-full h-full relative">
      <div
        @click="close"
        class="w-full h-full absolute top-0 left-0"
        style="background-color: rgba(0, 0, 0, 0.3)"
      ></div>
      <div
        class="w-300px absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
      >
        <div
          class="w-full bg-grey04 text-center text-xs text-white00 font-bold leading-25px rounded-t-10px relative"
        >
          作業完了
          <div
            @click="close"
            class="w-14px h-14px bg-white00 rounded-full absolute top-1/2 right-10px transform -translate-y-1/2 cursor-pointer"
          >
            <div class="w-full h-full relative">
              <span
                class="h-px w-10px bg-grey04 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 rotate-45"
              ></span>
              <span
                class="h-px w-10px bg-grey04 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 -rotate-45"
              ></span>
            </div>
          </div>
        </div>
        <div class="w-full bg-white00 py-30px rounded-b-10px">
          <p class="text-center text-xs font-bold text-black01 mb-30px">
            作業を完了しますか？
          </p>
          <div class="flex justify-center items-center">
            <div
              @click="submit"
              class="block px-15px leading-loose text-white00 text-xs bg-grey04 rounded-20px mr-20px cursor-pointer"
            >
              完了する
            </div>
            <div
              @click="close"
              class="block px-20px leading-loose text-white00 text-xs bg-grey04 rounded-20px cursor-pointer"
            >
              やめる
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: String,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit", true);
    },
  },
};
</script>
