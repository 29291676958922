var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "w-full h-full absolute top-0 left-0 z-20",
        staticStyle: { "background-color": "rgba(0, 0, 0, 0.3)" }
      },
      [
        _c("div", { staticClass: "w-full h-full relative" }, [
          _c(
            "div",
            {
              staticClass:
                "w-300px absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-full bg-grey04 text-center text-xs text-white00 font-bold leading-25px rounded-t-10px relative"
                },
                [
                  _vm._v(
                    "\n                指示書PDFダウンロード\n                "
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-14px h-14px bg-white00 rounded-full absolute top-1/2 right-10px transform -translate-y-1/2 cursor-pointer"
                    },
                    [
                      _c("div", { staticClass: "w-full h-full relative" }, [
                        _c("span", {
                          staticClass:
                            "h-px w-10px bg-grey04 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 rotate-45"
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass:
                            "h-px w-10px bg-grey04 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 -rotate-45"
                        })
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-full bg-white00 py-30px rounded-b-10px" },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-center text-xs font-bold text-black01 mb-30px"
                    },
                    [_vm._v("指示書PDFをダウンロードしますか？")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex justify-center items-center" },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "block px-15px leading-loose text-white00 text-xs bg-grey04 rounded-20px mr-20px",
                          attrs: { href: "" }
                        },
                        [_vm._v("ダウンロード")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "block px-20px leading-loose text-white00 text-xs bg-grey04 rounded-20px",
                          attrs: { href: "" }
                        },
                        [_vm._v("やめる")]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }